import React from "react";
import "../styles.css";

const Education = () => {
    return (
        <div className="education">
            <h2>Education</h2>
            <ul>
                <li>Boston University: Master's of Science of Software Development(MSSD) (2024 - 2026)</li>
                <li>Salem State: Management Information Systems - (2015 - 2023)</li>
            </ul>
        </div>
    );
};

export default Education;