import React from "react";
import "../styles.css";

const Awards = () => {
    return (
        <div className="skills">
            <h2>Awards</h2>
            <ul>
                <li><a href="https://www.linkedin.com/in/marino-jules/details/certifications/">LeanData Routing
                    Certification</a></li>
                <li><a href="https://skillshop.exceedlms.com/student/award/ThFm7SqLqhoKN5ykgwVvyQqX">Google Ads Search
                    Certification</a></li>
                <li><a href="https://www.insidesherpa.com/firm-highlights/Sj7temL583QAYpHXD/uJpLcxZeY6o9n3fy7">Software
                    Engineering Virtual Certificate</a></li>
                <li><a href="https://www.credential.net/00109087-29a6-4efa-9f8a-d6077cf8b046">Adobe Certified
                    Expert-Marketo Engage Business Practitioner</a></li>
                <li><a href="https://www.credly.com/badges/8b1795e2-1f8b-4ba9-abd8-8f6dbcb4d14f/public_url">OneTrust
                    Consent & Preference Management Expert</a></li>
                <li><a href="https://www.credly.com/badges/305275df-fa3d-4f4f-ad99-e0a319dc247f/public_url">OneTrust
                    Cookie Consent Expert</a></li>
            </ul>
        </div>
    );
};

export default Awards;