import React from "react";
import "../styles.css"

const About = () => {
    return (
        <div className="about">
            <h2>About Me</h2>
            <p>
                My name is Marino Jules and I have over 5 years of experience in digital marketing, marketing operations
                and product management.
                I am currently enrolled in a Master's Program in Software Development at the Boston University
                Metropolitan College. I have always enjoyed
                working on websites and web development and am excited to dive deeper into creating web applications.
                This website is an opportunity
                to extend some of what I have learned during this course.
                If you'd like to get in touch with me, please click here.
            </p>
        </div>
    );
};

export default About;